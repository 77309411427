<template>
    <div style="display: flex;flex-direction: column;padding: 0px 0px;box-sizing: border-box;width: 100vw;height: 100vh;">
        <Header :title="'意见反馈'" :leftClick="goBack">
        </Header>
        <p style="color: #333333;font-size: 16px;margin-left: 20px;">反馈列表</p>
        <div style="flex: 1;overflow-y: auto;padding: 0px 20px;box-sizing: border-box;">
            <div v-for="(content, index) in dataList" :key="index"
                style="width: 100%;padding: 15px 0px;border-bottom: 1px solid #F0F1F5;margin-bottom: 10px;">
                <p style="color: #9698a2;font-size: 13px;">{{ formatMsgTime(content.create_time) }}</p>
                <p style="margin-top: 10px;color: #262c3a;font-size: 14px;">{{ content.message_content }}</p>
                <p v-if="content.reply_text" style="margin-top: 10px;color: #262c3a;font-size: 14px;">{{
                    content.reply_text }}</p>
            </div>
        </div>
        <div style="display: flex;padding: 15px 20px;box-sizing: border-box;">
            <el-input style="flex: 1;width: 100%;padding-right: 10px;box-sizing: border-box;" v-model="info"
                placeholder="请输入"></el-input>
            <div class="btn-send" @click="doSubmit">发表</div>
        </div>
    </div>
</template>
<script>
import Header from "../../components/app/Header.vue";
import {
    findMyMessages,
    sendMessage
} from '../../api/app'
import {
    formatDate
} from '../../utils/date'
export default ({
    name: 'index',
    components: {
        Header,
    },
    data() {
        return {
            dataList: [],
            info: ''
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        formatMsgTime(time) {
            return formatDate(new Date(time * 1000), 'Y/mm/dd hh:MM')
        },

        fetchData() {
            findMyMessages().then((res) => {
                if (res.data.code == 0) {
                    this.dataList = res.data.data
                }

            })
        },
        doSubmit() {
            if (this.info == '') {
                this.$message.error('请输入内容')
                return
            }
            sendMessage(this.info).then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.$message.success('提交成功')
                    this.goBack()
                } else {
                    this.$message.error(result.msg)
                }
            })

        },
        goBack() {
            this.$router.go(-1)
        }
    }
})
</script>
<style lang="less" scoped>
.btn-send {
    width: 80px;
    cursor: pointer;
    color: white;
    background-color: #5d5bb5;
    border-radius: 8px;
    font-size: 16px;
    height: 40px;
    text-align: center;
    line-height: 40px;
}

/deep/ .el-input__inner {
    border-color: #5854b2;
    color: #5d5bb5;
}
</style>